import React from 'react';
import styled from 'styled-components';
import { FaMapMarkedAlt, FaHandsHelping, FaCheck } from 'react-icons/fa';
import Card from './Card';
import { Title } from '../../common';

const StyledSection = styled.section`
  padding-top: 10rem;

  @media screen and (min-width: 1024px) {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-top: 0;
  }
`;

const Cards = styled.div`
  @media screen and (min-width: 1024px) {
    flex: 0 0 calc(60% - 2rem);
    display: grid;
    grid-column-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1680px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export interface AboutUs {
  heading: string;
  text: string;
}

export interface Props {
  subtitle: string;
  cardsData: AboutUs[];
}

const About: React.FC<Props> = ({ subtitle, cardsData }) => {
  return (
    <StyledSection id="whybluemarine">
      <Title heading="Why Blue Marine ?" text={subtitle} />
      <Cards>
        {cardsData.map((cardData, index) => (
          <Card
            title={cardData.heading}
            text={cardData.text}
            key={cardData.heading}
            order={index}
          >
            {cardData.heading === 'Large Network' ? <FaMapMarkedAlt /> : ''}
            {cardData.heading === 'Customer Support' ? <FaHandsHelping /> : ''}
            {cardData.heading === 'Success' ? <FaCheck /> : ''}
          </Card>
        ))}
      </Cards>
    </StyledSection>
  );
};

export default About;
