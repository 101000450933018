import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Typed from 'react-typed';
import LightSpeed from 'react-reveal/LightSpeed';
import { Desktop, Mobile } from '../../helpers';

const StyledLanding = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  h1 {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 2rem;
    padding-bottom: 2.5rem;
  }

  a {
    background: transparent;
    border: 0.1rem solid;
    border-radius: 0.5rem;
    color: white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }

  a:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: 1.9rem;
      line-height: 3rem;
      letter-spacing: 0.2rem;
      padding-bottom: 3.5rem;
    }

    a {
      font-size: 1.3rem;
      padding: 0.8rem 1.6rem;
    }
  }

  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 1.5rem;
      line-height: 3rem;
      letter-spacing: 0.2rem;
      padding-bottom: 3.5rem;
      width: 70vw;
    }

    a {
      font-size: 1.1rem;
      padding: 0.5rem 1rem;
    }
  }

  @media screen and (min-width: 1280px) {
    h1 {
      font-size: 1.9rem;
      line-height: 3rem;
      letter-spacing: 0.2rem;
      padding-bottom: 3.5rem;
      width: 50vw;
    }

    a {
      font-size: 1.3rem;
      padding: 0.8rem 1.6rem;
    }
  }

  @media screen and (min-width: 1680px) {
    h1 {
      width: 40vw;
      font-size: 2rem;
    }

    a {
      font-size: 1.4rem;
    }
  }
`;
export interface Props {
  title: string;

  startDelay?: number;
  typeSpeed?: number;
  buttonText?: string;
  buttonDelay?: number;
}

const Landing: React.FC<Props> = ({
  title,
  startDelay,
  typeSpeed,
  buttonText,
  buttonDelay,
}) => {
  return (
    <StyledLanding id="landing">
      <h1>
        <Typed
          strings={[`${title}`]}
          startDelay={startDelay}
          typeSpeed={typeSpeed}
        />
      </h1>
      <div>
        <LightSpeed left duration={1000} delay={buttonDelay}>
          <Desktop>
            <a href="#services">{buttonText}</a>
          </Desktop>
          <Mobile>
            <AnchorLink href="#whatweoffer">{buttonText}</AnchorLink>
          </Mobile>
        </LightSpeed>
      </div>
    </StyledLanding>
  );
};

Landing.defaultProps = {
  startDelay: 2000,
  typeSpeed: 30,
  buttonText: 'Discover More',
  buttonDelay: 6000,
};

export default Landing;
