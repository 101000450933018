import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';
import { FluidObject } from 'gatsby-image';
import { Title } from '../../common';
import Card from './Card';
import useDesktop from '../../../hooks/useDesktop';
import slugify from '../../helpers/slugify';

const StyledSection = styled.section`
  padding-top: 10rem;

  @media screen and (min-width: 1024px) {
    display: flex;
    min-height: 100vh;
    align-items: center;
    padding-top: 4rem;
  }
`;
const Cards = styled.div`
  margin: 3rem 0;

  @media screen and (min-width: 1024px) {
    flex: 0 0 calc(60% - 2rem);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
  }

  @media screen and (min-width: 1280px) {
    flex: 0 0 calc(60% - 4rem);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }

  @media screen and (min-width: 1680px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export interface Product {
  courseTitle: string;
  courseImage: { childImageSharp: { fluid: FluidObject } };
  desktop: { childImageSharp: { fluid: FluidObject } };
  tablet: { childImageSharp: { fluid: FluidObject } };
  mobile: { childImageSharp: { fluid: FluidObject } };
  subjects: { subject: string }[];
}
export interface Props {
  title: string;
  subtitle: string;
  products: Product[];
}

const Products: React.FC<Props> = ({ title, subtitle, products }) => {
  const isDesktop = useDesktop();
  const { width } = useWindowSize();

  const getImage = (product: Product): FluidObject => {
    let image;
    if (isDesktop) {
      image = product.desktop.childImageSharp.fluid;
    } else if (width > 768) {
      image = product.tablet.childImageSharp.fluid;
    } else {
      image = product.mobile.childImageSharp.fluid;
    }
    return image;
  };

  const cards = products.map((product, index) => (
    <Card
      image={getImage(product)}
      title={product.courseTitle}
      anchor={slugify(product.courseTitle)}
      key={product.courseTitle}
      list={product.subjects}
      even={index % 2 === 0}
      order={index}
    />
  ));

  return (
    <StyledSection id="whatweoffer">
      <Title heading={title} text={subtitle} />
      <Cards>{cards}</Cards>
    </StyledSection>
  );
};
export default Products;
