import React from 'react';
import styled from 'styled-components';
import { FaMapMarkerAlt, FaPhone, FaRegEnvelope } from 'react-icons/fa';
import { BsFillPeopleFill } from 'react-icons/bs';

const StyledAddress = styled.article`
  flex: 0 0 100%;

  @media screen and (min-width: 1024px) {
    flex: 0 0 50%;
  }
`;
const StyledDiv = styled.div`
  display: flex;

  svg {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    svg {
      font-size: 2rem;
    }
  }

  @media screen and (min-width: 1024px) {
    svg {
      font-size: 1.3rem;
    }
  }

  @media screen and (min-width: 1280px) {
    svg {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: 1680px) {
    svg {
      font-size: 1.9rem;
    }
  }
`;

const Col = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 49.5%;
  flex-flow: column nowrap;

  h3 {
    font-size: 1rem;
    font-weight: normal;
    margin: 0.9rem 0;
  }

  a {
    color: white;
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 0.1rem;
  }

  div {
    margin-top: 0.5rem;

    svg {
      font-size: 0.7rem;
    }
  }

  @media screen and (min-width: 768px) {
    h3 {
      font-size: 1.7rem;
    }

    a {
      font-size: 1.4rem;
      margin-left: 0.5rem;
    }

    div {
      margin-top: 1rem;

      svg {
        font-size: 1rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 1.5rem 0;

    h3 {
      font-size: 1rem;
    }

    a {
      font-size: 0.8rem;
    }

    div {
      margin-top: 0.7rem;

      svg {
        font-size: 0.7rem;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    padding: 3.4rem 0;

    h3 {
      font-size: 1rem;
    }

    a {
      font-size: 0.9rem;
    }

    div {
      margin: 0.7rem 0;

      svg {
        font-size: 0.7rem;
      }
    }
  }

  @media screen and (min-width: 1680px) {
    padding: 3.1rem 0;

    h3 {
      font-size: 1.3rem;
    }

    a {
      font-size: 1.1rem;
    }

    div {
      margin: 1.2rem 0;

      svg {
        font-size: 0.9rem;
      }
    }
  }
`;

const EmailWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  margin: 1.3rem 0;

  a {
    color: white;
    letter-spacing: 0.1rem;
    margin-left: 0.3rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
    margin: 1rem 0;
  }

  @media screen and (min-width: 1024px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 1680px) {
    font-size: 1.2rem;
  }
`;

const Divider = styled.div`
  border-left: 0.1rem solid white;
  flex: 0 0 1%;
`;

interface Props {
  contactData: {
    phoneNumbers: { phone1: string; phone2: string };
    contactMail: string;
    ceo: { name: string; mail: string };
    cfo: { name: string; mail: string };
    coo: { name: string; mail: string };
    address: {
      line1: string;
      line2: string;
      mapLink: string;
    };
  };
}

const Address: React.FC<Props> = ({ contactData }) => {
  return (
    <StyledAddress>
      <StyledDiv>
        <Col>
          <FaMapMarkerAlt />
          <h3>Alexandria - Egypt</h3>
          <a
            href={contactData.address.mapLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactData.address.line1}
          </a>
          <a
            href={contactData.address.mapLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactData.address.line2}
          </a>

          <div>
            <FaPhone />
            <a href={`tel:${contactData.phoneNumbers.phone1}`}>
              {contactData.phoneNumbers.phone1}
            </a>
          </div>
          <div>
            <FaPhone />
            <a href={`tel:${contactData.phoneNumbers.phone2}`}>
              {contactData.phoneNumbers.phone2}
            </a>
          </div>
        </Col>
        <Divider />
        <Col>
          <BsFillPeopleFill />
          <h3>Management Team</h3>
          <a
            href={`mailto:${contactData.ceo.mail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactData.ceo.name}
          </a>
          <br />
          <a
            href={`mailto:${contactData.cfo.mail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactData.cfo.name}
          </a>
          <br />
          <a
            href={`mailto:${contactData.coo.mail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactData.coo.name}
          </a>
        </Col>
      </StyledDiv>
      <EmailWrapper>
        <FaRegEnvelope />
        <a
          href={`mailto:${contactData.contactMail}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {contactData.contactMail}
        </a>
      </EmailWrapper>
    </StyledAddress>
  );
};

export default Address;
