import React, { useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import Img, { FluidObject } from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Fade from 'react-reveal/Fade';
import useDesktop from '../../../hooks/useDesktop';
import { Desktop, Mobile, animations } from '../../helpers';

/* stylelint-disable */
const cardsAnimationEven = (): FlattenSimpleInterpolation => css`
  ${animations.imageAnimationEven} 10s infinite
`;
const cardsAnimationOdd = (): FlattenSimpleInterpolation => css`
  ${animations.imageAnimationOdd} 10s infinite
`;
/* stylelint-enable */
interface StyledArticle {
  even: boolean;
}

const StyledArticle = styled.article<StyledArticle>`
  ul {
    margin: 1.7rem auto 1rem;
    width: 70vw;

    a {
      color: white;
      font-size: 0.9rem;
      line-height: 1.9rem;
      margin-left: 1.3rem;
    }
  }

  ul li {
    font-size: 0.9rem;
    line-height: 1.4rem;
    list-style-position: inside;
  }

  ul li:last-child {
    list-style: none;
  }

  .gatsby-image-wrapper {
    border-radius: 2%;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.5);
    height: 40vw;
    margin: 0 auto;
    width: 70vw;

    /* stylelint-disable */

    img {
      animation: ${(props) =>
        props.even ? cardsAnimationEven : cardsAnimationOdd};
    }

    /* stylelint-enable */
  }

  @media screen and (min-width: 768px) {
    ul {
      a {
        font-size: 1.2rem;
        margin-left: 1.8rem;
      }
    }

    ul li {
      font-size: 1.3rem;
      line-height: 1.7rem;
    }

    .gatsby-image-wrapper {
      height: 30vw;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 2rem;

    ul {
      display: none;
    }

    hr {
      display: none;
    }

    .gatsby-image-wrapper {
      border-radius: 0;
      width: 10vw;
      height: 4.5vw;
      margin: 0 auto;
      box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease-in-out;

      img {
        animation: none;
      }
    }

    a {
      text-decoration: none;

      h3 {
        font-size: 0.65rem;
        font-weight: normal;
        color: white;
        padding: 0.4rem 0;
        text-align: center;
        background: black;
        transition: all 0.3s ease-in-out;
      }
    }

    a:hover {
      h3 {
        transform: scale(1.21);
      }

      .gatsby-image-wrapper {
        transform: scale(1.2);
      }
    }
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 3rem;

    ul {
      display: none;
    }

    hr {
      display: none;
    }

    .gatsby-image-wrapper {
      border-radius: 0;
      width: 10vw;
      height: 4.5vw;
      margin: 0 auto;
      box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease-in-out;

      img {
        animation: none;
      }
    }

    a {
      text-decoration: none;

      h3 {
        font-size: 0.8rem;
        font-weight: normal;
        color: white;
        padding: 0.4rem 0;
        text-align: center;
        background: black;
        transition: all 0.3s ease-in-out;
      }
    }

    a:hover {
      h3 {
        transform: scale(1.21);
      }

      .gatsby-image-wrapper {
        transform: scale(1.2);
      }
    }
  }

  @media screen and (min-width: 1680px) {
    .gatsby-image-wrapper {
      width: 10vw;
      height: 4.5vw;
    }

    a {
      h3 {
        font-size: 1rem;
      }
    }
  }
`;

const CourseTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: normal;
  padding: 4rem 1rem 1rem;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 2.2rem;
    padding: 5rem 1rem 1rem;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;
interface Props {
  list: { subject: string }[];
  anchor: string;
  title: string;
  image: FluidObject;
  order: number;
  even: boolean;
}

const Card: React.FC<Props> = ({ list, anchor, title, image, order, even }) => {
  const isDesktop = useDesktop();
  const [currentList, setCurrentList] = useState(list.slice(0, 4));
  const tall = list.length > 4;
  const [collapsed, setCollapsed] = useState(list.length > 4);
  const expand = (): void => {
    setCurrentList(list);
    setCollapsed(false);
  };
  const collapse = (): void => {
    setCurrentList(list.slice(0, 4));
    setCollapsed(true);
  };
  return (
    <Fade
      right
      duration={isDesktop ? 1000 : 0}
      delay={isDesktop ? 1000 + order * 100 : 0}
    >
      <StyledArticle even={even}>
        <CourseTitle id={`services/${anchor}`}>{title}</CourseTitle>
        <Desktop>
          <a href={`#services/${anchor}`}>
            <Img fluid={image} alt={title} />
            <h3>{title}</h3>
          </a>
        </Desktop>
        <Mobile>
          <Img fluid={image} alt={title} />
        </Mobile>
        <ul>
          {currentList.map((entry) => (
            <li key={entry.subject}>{entry.subject}</li>
          ))}
          {tall && (
            <>
              {collapsed && (
                <li>
                  <AnchorLink
                    href={`#services/${anchor}`}
                    onClick={() => expand()}
                  >
                    More +
                  </AnchorLink>
                </li>
              )}
              {!collapsed && (
                <li>
                  <AnchorLink
                    href={`#services/${anchor}`}
                    onClick={() => collapse()}
                  >
                    Less -
                  </AnchorLink>
                </li>
              )}
            </>
          )}
        </ul>
        <hr />
      </StyledArticle>
    </Fade>
  );
};

export default Card;
