import * as React from 'react';
import styled, { css } from 'styled-components';
import { Title } from '../../common';

const StyledArticle = styled.article`
  flex: 0 0 100%;

  @media screen and (min-width: 1024px) {
    flex: 0 0 50%;
  }
`;

const StyledFormField = css`
  background: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 0.3rem;
  color: white;
  display: inline-block;
  font-family: montserrat, sans-serif;
  font-size: 0.8rem;
  height: 2rem;
  margin: 0.5rem;
  padding-left: 0.4rem;
  width: ${(props: any) =>
    props.half ? 'calc(50% - 1rem)' : 'calc(100% - 1rem)'};

  &:focus {
    background: rgba(255, 255, 255, 0.1);
    outline: 0.1rem solid white;
  }

  &::placeholder {
    color: white;
    letter-spacing: 0.05rem;
  }

  @media screen and (min-width: 768px) {
    height: 3rem;
    margin: 0.7rem;
    font-size: 1.1rem;
    width: ${(props: any) =>
      props.half ? 'calc(50% - 1.4rem)' : 'calc(100% - 1.4rem)'};
  }

  @media screen and (min-width: 1024px) {
    height: 1.8rem;
    font-size: 0.9rem;
    margin: 0.4rem;
    width: ${(props: any) =>
      props.half ? 'calc(50% - 0.8rem)' : 'calc(100% - 0.8rem)'};
  }

  @media screen and (min-width: 1680px) {
    height: 2rem;
    font-size: 0.9rem;
    margin: 0.5rem;
    width: ${(props: any) =>
      props.half ? 'calc(50% - 1rem)' : 'calc(100% - 1rem)'};
  }
`;
const FormField = styled.input<{ half?: string; autocomplete?: string }>`
  ${StyledFormField}
`;

const FormArea = styled.textarea<{ width: string; autocomplete?: string }>`
  ${StyledFormField}
  height: 8rem;
  padding: 0.4rem;

  @media screen and (min-width: 768px) {
    height: 10rem;
  }

  @media screen and (min-width: 1024px) {
    height: 4rem;
  }

  @media screen and (min-width: 1680px) {
    height: 8rem;
  }
`;

const SubmitButton = styled.input`
  background: transparent;
  border: 0.1rem solid white;
  border-radius: 0.5rem;
  color: white;
  display: block;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  margin: 0.5rem auto 5rem;
  outline: none;
  padding: 0.5rem 1rem;
  width: calc(100% - 1rem);

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  @media screen and (min-width: 768px) {
    font-size: 1.3rem;
    padding: 0.8rem 1.6rem;
    width: calc(100% - 1.4rem);
  }

  @media screen and (min-width: 1024px) {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    width: 20%;
    margin: 0 0 0 0.5rem;
  }

  @media screen and (min-width: 1280px) {
    width: 20%;
    margin: 1rem 1rem 1rem 0.5rem;
  }
`;

const ContactForm: React.FC = () => (
  <StyledArticle>
    <Title heading="Drop us a line" text="" />
    <form action="https://formspree.io/" method="POST">
      <FormField
        type="text"
        name="name"
        id="name"
        placeholder="Name"
        half="true"
        required
      />
      <FormField
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        half="true"
        required
      />
      <FormField
        type="text"
        name="subject"
        id="subject"
        placeholder="Subject"
        required
        autocomplete="off"
      />
      <FormArea
        placeholder="Your message here ..."
        width="95%"
        name="message"
        required
        autocomplete="off"
        id="message"
      />
      <SubmitButton type="submit" value="Send" />
    </form>
  </StyledArticle>
);

export default ContactForm;
