import styled from 'styled-components';

const Desktop = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;
export default Desktop;
