import { keyframes } from 'styled-components';

const imageAnimationOdd = keyframes`
0% {
  transform: scale(1);
}

50% {
  transform: scale(1.2);
}

100% {
  transform: scale(1);
}
`;
const imageAnimationEven = keyframes`
0% {
  transform: scale(1.2);
}

50% {
  transform: scale(1);
}

100% {
  transform: scale(1.2);
}
`;

export default {
  imageAnimationOdd,
  imageAnimationEven,
};
