import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import useDesktop from '../../../hooks/useDesktop';

const StyledArticle = styled.article`
  margin-top: 2.5rem;

  svg {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.3rem;
    font-weight: normal;
    margin-left: 1rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5rem;
    text-indent: 1.5rem;
  }

  div {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 768px) {
    margin-top: 5.5rem;

    svg {
      font-size: 3.5rem;
    }

    h3 {
      font-size: 2rem;
      margin-left: 1rem;
    }

    p {
      line-height: 1.9rem;
      text-indent: 1.8rem;
      font-size: 1.5rem;
    }

    div {
      justify-content: center;
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: 1rem;

    svg {
      font-size: 1.4rem;
    }

    div {
      margin-bottom: 0.3rem;
      justify-content: flex-start;
    }

    h3 {
      font-size: 1.3rem;
      margin-left: 1.5rem;
    }

    p {
      line-height: 0.9rem;
      text-indent: 0;
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 1280px) {
    svg {
      font-size: 2rem;
    }

    div {
      margin-bottom: 1rem;
      justify-content: flex-start;
    }

    h3 {
      font-size: 1.5rem;
      margin-left: 1.5rem;
    }

    p {
      line-height: 1.2rem;
      text-indent: 0;
      font-size: 0.9rem;
    }
  }

  @media screen and (min-width: 1680px) {
    svg {
      font-size: 2.3rem;
    }

    div {
      margin-bottom: 1.3rem;
    }

    h3 {
      font-size: 1.7rem;
      margin-left: 1.5rem;
    }

    p {
      line-height: 1.3rem;
      word-spacing: 0.2rem;
      text-indent: 0;
      font-size: 1.1rem;
    }
  }
`;
type Props = {
  order: number;
  title: string;
  text: string;
};

const Card: React.FC<Props> = ({ order, title, text, children }) => {
  const isDesktop = useDesktop();
  return (
    <Fade
      right
      duration={isDesktop ? 1000 : 0}
      delay={isDesktop ? 1000 + order * 100 : 0}
    >
      <StyledArticle>
        <div>
          {children}
          <h3>{title}</h3>
        </div>
        <p>{text}</p>
      </StyledArticle>
    </Fade>
  );
};

export default Card;
